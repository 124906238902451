/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import {
    Button,
    ColumnWrap,
    CustomColumn,
    PaymentPageWrap,
    PolicyWrap,
    PaymentOptionOutlineButton,
    PaymentImg,
} from '@components/shared/View';
import { useGlobalProps } from '@hooks/useGlobalProps';
import VNPaymentModal from '@components/shared/modals/VNPaymentModel';
import AlertModal from '@components/shared/modals/AlertModal';
import { FONT_SIZE } from '@constants/numbers';
import styled from 'styled-components';
import { getDPolicy } from '@api/other';
import { getVNDepositV3, } from '@api/payment';
import { getUserInfo } from '@api/user';

import { displayAmountWithPrecision } from '@utils/util';
import { Spin, message } from 'antd';
import AmountColumn from '@components/shared/AmountColumn';
// import { IS_AW_VN_PROD } from '@constants/config';

const INIT_ALERT = {
    show: false,
    title: '',
};

const PAYMENT_OPTIONS = [
    {
        name: 'qrcode',
        imagePath: '/images/payments/vn/QR-CODE_EN.png',
        code: 909,
        channel: 'vnd_wysm_03'
    }
];

export default function DepositVNV3Page() {
    const [inputField, setInputField] = useState({ amount: '' });
    const [paymentModalInfo, setPaymentModalInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [alert, setAlert] = useState({ ...INIT_ALERT });
    const [policy, setPolicy] = useState(null);
    const {
        user,
        userData,
        depositAmountOptions,
        mainUnit,
        mainUnitRaw,
        secondUnitRaw,
        unitRate,
        getBalance,
        pixelCodes,
        depositStepperOptions
    } = useGlobalProps(); //, balance

    const { i18n, t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tPayment = t('payment', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const [paymentOption, setPaymentOption] = useState({
        name: 'qrcode',
        imagePath: '/images/payments/vn/QR-CODE_EN.png',
        code: 909,
        channel: 'vnd_wysm_03'
    });
    const [messageApi, contextHolder] = message.useMessage();

    const [amountInfos, setAmountInfos] = useState([]);



    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);




    useEffect(() => {
        if (Object.keys(depositAmountOptions).length > 0) {
            const infos = [];
            if (depositAmountOptions.amounts) {
                for (const amount of depositAmountOptions.amounts) {
                    const info = {
                        amount,
                        currency: depositAmountOptions.currency,
                        pointUnit: depositAmountOptions.pointUnit,
                        points: depositAmountOptions.rate
                            ? amount * parseFloat(depositAmountOptions.rate)
                            : 0,
                        rate: depositAmountOptions.rate
                            ? parseFloat(depositAmountOptions.rate)
                            : 0,
                    };
                    infos.push(info);
                }
            }

            setAmountInfos(infos);
        }
    }, [depositAmountOptions]);

    const getPolicy = async () => {
        const res = await getDPolicy(i18n.language);
        if (res && res[0]) setPolicy(res[0]);
        else setPolicy(null);
    };

    const maxAmount = user?.ki_max || 0;
    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        let val = target.value;
        if (name === 'amount' && maxAmount > 0) {
            if (val > maxAmount) val = maxAmount;
        }

        setInputField((prev) => {
            return { ...prev, [name]: val };
        });
    };

    const checkError = () => {
        let message = null;

        if (!inputField.amount) {
            message = tError['error@must_fill_amount'];
        }

        try {
            if (
                (!depositAmountOptions || !depositAmountOptions.display) &&
                parseInt(inputField.amount) < userData?.bank_in_min
            ) {
                message = tError['error@deposit_insufficient_amount'];
            }
        } catch (e) {
            message = tError['error@deposit_insufficient_amount'];
        }

        return message;
    };

    useEffect(() => {
        if (userData?.user_name)
            setInputField((prev) => { return { ...prev, accountName: userData.user_name } });
    }, [userData]);
    useEffect(() => {
        getPolicy();
    }, [i18n.language]);

    const openApp = () => {
        const userAgent = navigator.userAgent || navigator.vendor;
        const info = PAYMENT_OPTIONS[paymentOption];
        if (/android/i.test(userAgent)) {
            window.location.assign(info.appSchema);

            setTimeout(() => {
                window.location.href = info.googleplayUrl;
            }, 1000);
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location = info.appSchema;
            setTimeout(() => {
                window.location.href = info.appstoreUrl;
            }, 1000);
        } else {
            // Other platforms
            window.open(info.appstoreUrl, '_blank');
            // window.location.href = info.appstoreUrl;
        }
    };

    const handleSelectAmount = (val) => {
        setInputField((prev) => {
            if (depositStepperOptions && depositStepperOptions.display && parseFloat(val) > 0 && prev.amount !== '') {
                if (maxAmount > 0 && (parseFloat(prev.amount) + parseFloat(val) > maxAmount)) {
                    return { ...prev, amount: maxAmount };
                }
                return { ...prev, amount: parseFloat(prev.amount) + parseFloat(val) };
            } else {
                return { ...prev, amount: val === 0 ? '' : val };
            }
        });
    };


    const onCopy = () => {
        const textarea = document.createElement('textarea');
        textarea.textContent = paymentModalInfo.acc;
        document.body.appendChild(textarea);
        textarea.select();

        if (document.execCommand('copy')) {
            messageApi.open({
                type: 'success',
                content: tPayment['payment@acc-copied-success'],
            });
        } else {
            messageApi.open({
                type: 'warning',
                content: tPayment['payment@acc-copied-failed'],
            });
        }
        document.body.removeChild(textarea);
    };

    const onPaymentOptionClick = (option) => {
        setPaymentOption(option);
    };

    const displayPolicy = policy && policy.content?.length > 0;
    return (
        <PaymentPageWrap>
            {contextHolder}


            <AffiliateOverview />
            <ColumnWrap className="mt-20 mb-1 pl-3" alignItems="start">
                <CustomColumn
                    title={tPayment['payment@deposit-method']}
                    child={
                        <>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start', alignItems: 'center', gap: '8px' }}>
                                {PAYMENT_OPTIONS.map((option) => {

                                    return <PaymentOptionOutlineButton
                                        key={option.name}
                                        onClick={() => onPaymentOptionClick(option)}
                                        name={option.name}
                                        isSelected={
                                            paymentOption && paymentOption.name === option.name
                                        }
                                    >
                                        <PaymentImg
                                            width={option.imageWidth ? option.imageWidth : '100px'}
                                            src={option.imagePath}
                                            alt={option.name}
                                            name={option.name}
                                        />
                                    </PaymentOptionOutlineButton>
                                })}
                            </div>
                        </>
                    }
                />
                <AmountColumn
                    title={tPayment['payment@deposit-amount']}
                    mainUnit={mainUnit}
                    displayAmountOptions={depositAmountOptions && depositAmountOptions.display}
                    amount={inputField.amount}
                    onAmountChange={handleInputChange}
                    onSelecteAmount={handleSelectAmount}
                    amountInfos={amountInfos}
                    displayStepper={depositStepperOptions && depositStepperOptions.display}
                />

                <CustomColumn
                    notRequired
                    child={
                        <DepositInfo>
                            <p>
                                {userData?.bank_in_min > 0 && !maxAmount && `${tPayment['payment@deposit-min-amount']}: ${displayAmountWithPrecision(
                                    userData?.bank_in_min
                                )}${mainUnit && ` ${mainUnit}`
                                    }`}
                                {!userData?.bank_in_min && maxAmount > 0 && `${tPayment['payment@deposit-max-amount']}: ${displayAmountWithPrecision(
                                    maxAmount
                                )}${mainUnit && ` ${mainUnit}`
                                    }`}
                                {userData?.bank_in_min > 0 && maxAmount > 0 && `${tPayment['payment@deposit-amount-limit']
                                    }: ${displayAmountWithPrecision(
                                        userData?.bank_in_min
                                    )} ~ ${displayAmountWithPrecision(
                                        maxAmount
                                    )}${mainUnit && ` ${mainUnit}`
                                    }`}
                            </p>
                        </DepositInfo>
                    }
                />

                <CustomColumn
                    child={
                        <SpinContainer>
                            {isLoading ? (
                                <Spin
                                    style={{
                                        top: '25%',
                                        left: '25%',
                                        position: 'absolute',
                                    }}
                                />
                            ) : (
                                <Button
                                    disabled={!!user === false}
                                    onClick={async () => {

                                        const message = checkError();
                                        if (message) {
                                            setAlert({
                                                show: true,
                                                title: tCommon['common@failed'],
                                                message: message,
                                            });
                                            return;
                                        }
                                        if (pixelCodes && pixelCodes.length > 0) {
                                            pixelCodes.forEach((code) => window.fbq('trackSingleCustom', code, 'DepositSubmission'));
                                        }
                                        setIsLoading(true);



                                        try {

                                            let ukey = user.ukey;

                                            const userInfoRes = await getUserInfo({ dvc: user?.device_id });


                                            if (userInfoRes.ukey) {
                                                ukey = userInfoRes.ukey;
                                            }


                                            const res = await getVNDepositV3(paymentOption.channel, {
                                                amount: parseFloat(parseFloat(inputField.amount).toFixed(2)),
                                                acc: user.acc,
                                                ukey: ukey,
                                                remark: ''
                                            });


                                            if (res?.result && res?.result.success && res?.data && res?.data.paymentUrl) {
                                                setInputField({ amount: '' });

                                                // navigate(SWITCH_ROUTES.DEPOSIT_BANKING, {
                                                //     state: {
                                                //         gameUrl: res?.url,
                                                //     },
                                                // });
                                                window.location.href = res?.data.paymentUrl


                                                // newTab.location.href = res.url;
                                            } else {
                                                // newTab.close();
                                                let message = tError['error@deposit'];
                                                if (res.result && res.result.code && res.result.message) {
                                                    message = `${res.result.message}. ${tError['error@error-code']} : ${res.result.code}`;

                                                }
                                                setAlert({
                                                    show: true,
                                                    title: tCommon['common@failed'],
                                                    message: message,
                                                });
                                            }
                                        } catch (error) {
                                            setAlert({
                                                show: true,
                                                title: tCommon['common@failed'],
                                                // message: parseErrorRes(tError, res),
                                                message: tError['error@server_error'],
                                            });
                                        }


                                        setIsLoading(false);
                                    }}
                                >
                                    {tCommon['common@submit']}
                                </Button>
                            )}
                        </SpinContainer>
                    }
                    notRequired
                />
            </ColumnWrap>

            {
                displayPolicy && (
                    <PolicyWrap>
                        <div dangerouslySetInnerHTML={{ __html: policy.content }} />
                    </PolicyWrap>
                )
            }

            <VNPaymentModal
                show={paymentModalInfo != null}
                title={tPayment['payment@confirm-deposit']}
                children={
                    <>
                        {paymentModalInfo && (
                            <PaymentInfo>
                                <div>
                                    <PaymentImg
                                        width={'128px'}
                                        src={`/images/payments/${paymentOption}.jpg`}
                                    />
                                    <label>{paymentModalInfo.acc}</label>
                                    <div
                                        className="copy-icon"
                                        onClick={onCopy}
                                    />
                                </div>
                                <div>
                                    <label>
                                        {tPayment['payment@deposit-amount']}
                                    </label>
                                    <label>
                                        {`${displayAmountWithPrecision(
                                            Number(paymentModalInfo.amount)
                                        )}${mainUnitRaw && ` ${mainUnitRaw}`}${secondUnitRaw && unitRate && ` (${inputField.amount * parseFloat(unitRate)} ${secondUnitRaw})`
                                            }`}
                                    </label>
                                </div>
                            </PaymentInfo>
                        )}
                    </>
                }
                onConfirm={() => {
                    setPaymentModalInfo(null);
                    setInputField({ amount: '' });
                    openApp();
                }}
                onCancel={() => {
                    setPaymentModalInfo(null);
                    setInputField({ amount: '' });
                }}
            />
            <AlertModal
                {...alert}
                onConfirm={() => setAlert({ ...INIT_ALERT })}
            />
        </PaymentPageWrap >
    );
}

const DepositInfo = styled.div`
    color: black;
    > p {
        margin: 0;
        margin-top: 5px;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 800;
    }
    .text-center {
        text-align: center;
    }
`;

const SpinContainer = styled.div`
    textalign: center;
    position: relative;
    width: 100px;
    height: 40px;
`;

const PaymentInfo = styled.div`
    > div {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;
    }

    .copy-icon {
        cursor: pointer;
        mask-image: url('/images/shared/copy.svg');
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        min-width: 18px;
        min-height: 18px;
        background: black;
    }
`;
