import { APIS } from '@constants/api';
import { IS_AW_KE_PROD, IS_BITSBET_PROD } from '@constants/config';
import { generalFetch } from '@utils/generalFetch';
import { objectToQueryString } from '@utils/util';

export const getFriendList = (req) => {
    // ?ukey=da3520c1-90b0-b6e4-fdac-4a03e3c53507
    return generalFetch(
        `${APIS.PAYMENT.GET_FRIEND_LIST}${objectToQueryString(req)}`,
        'GET'
    );
};
export const transfer = (req) => {
    // ?toacc=MY111111B&val=20&ukey=6cc133ee-f0a7-59dd-30eb-c60597a8dd18&tkey=7923647f-a7b4-d0b9-0801-1189630567dc
    return generalFetch(
        `${APIS.PAYMENT.TRANSFER}${objectToQueryString(req)}`,
        'GET'
    );
};
export const deposit = (req) => {
    // ?ukey=00f9b37e-c5ac-8742-fc7b-57c99798b818&val=100&unm=tester
    return generalFetch(
        `${APIS.PAYMENT.DEPOSIT}${objectToQueryString(req)}`,
        'GET'
    );
};
export const withdrawal = (req) => {
    // ?ukey=da3520c1-90b0-b6e4-fdac-4a03e3c53507&val=100
    return generalFetch(
        `${APIS.PAYMENT.WITHDRAWAL}${objectToQueryString(req)}`,
        'GET'
    );
};
export const doBonusIn = (req) => {
    // ?val=33&ukey=acceb3be-f064-49e6-b26f-c8869f881d18&tkey=1286d3b7-8dd9-8b74-d90b-6189630890ec
    return generalFetch(
        `${APIS.PAYMENT.DO_BONUS_IN}${objectToQueryString(req)}`,
        'GET'
    );
};

export const setOptIn = (req) => {
    // ?val=33&ukey=acceb3be-f064-49e6-b26f-c8869f881d18&tkey=1286d3b7-8dd9-8b74-d90b-6189630890ec
    // return generalFetch(`${APIS.PAYMENT.DO_BONUS_IN}${objectToQueryString(req)}`, 'GET');
    //ukey, prcnm(半自動系統，每個國家要問建安), ub(客人銀行), ua(客人帳號), val(金額), ob(op銀行), oa(op帳號)
    return generalFetch(
        `${APIS.PAYMENT.SET_OP_IN}${objectToQueryString(req)}`,
        'GET'
    );
};

export const setOPout = (req) => {
    // ?val=33&ukey=acceb3be-f064-49e6-b26f-c8869f881d18&tkey=1286d3b7-8dd9-8b74-d90b-6189630890ec
    // return generalFetch(`${APIS.PAYMENT.DO_BONUS_IN}${objectToQueryString(req)}`, 'GET');
    //ukey, prcnm(半自動系統，每個國家要問建安), ub(客人銀行), ua(客人帳號), val(金額), ob(op銀行), oa(op帳號)
    return generalFetch(
        `${APIS.PAYMENT.SET_OP_OUT}${objectToQueryString(req)}`,
        'GET'
    );
};

export const getDepositBankAccountInfos = (req) => {
    return generalFetch(
        `${APIS.PAYMENT.GET_AGENT_BANK}${objectToQueryString(req)}`,
        'GET'
    );
};

export const getVNAccount = (req) => {
    return generalFetch(
        `${APIS.PAYMENT.VN_DEPOSIT}${objectToQueryString(req)}`,
        'GET'
    );
};

export const setVNWithdraw = (req) => {
    return generalFetch(
        `${APIS.PAYMENT.VN_WITHDRAW}${objectToQueryString(req)}`,
        'GET'
    );
};

export const getVNDepositV2 = (req) => {
    return generalFetch(
        `${APIS.PAYMENT.VN_DEPOSIT_V2}${objectToQueryString(req)}`,
        'GET'
    );
};

export const setVNWithdrawV2 = (req) => {
    return generalFetch(
        `${APIS.PAYMENT.VN_WITHDRAW_V2}${objectToQueryString(req)}`,
        'GET'
    );
};

export const getVNDepositV3 = (channel, req) => {
    return generalFetch(
        `${APIS.PAYMENT.VN_DEPOSIT_V3}/${channel}`,
        'POST',
        req
    );
};

export const setVNWithdrawV3 = (channel, req) => {
    return generalFetch(
        `${APIS.PAYMENT.VN_WITHDRAW_V3}/${channel}`,
        'POST',
        req
    );
};

export const getVNBankListV3 = () => {
    return generalFetch(
        `${APIS.PAYMENT.VN_V3_BANK}`,
        'GET'
    );
};



export const getPaymentUrl = (url, req) => {
    return generalFetch(
        `${url}${objectToQueryString(req)}`,
        'GET'
    );
};


export const GetKEDepositUrl = (req) => {
    return `${APIS.PAYMENT.KE_DEPOSIT}${objectToQueryString({ ...req, hd: (IS_AW_KE_PROD || IS_BITSBET_PROD) ? 'kea' : 'k1' })}`;
    // return generalFetch(
    //     `${APIS.PAYMENT.KE_DEPOSIT}${objectToQueryString(req)}`,
    //     'GET'
    // );

};

export const GetKEWithdrawUrl = (req) => {
    return `${APIS.PAYMENT.KE_WITHDRAW}${objectToQueryString({ ...req, hd: (IS_AW_KE_PROD || IS_BITSBET_PROD) ? 'kea' : 'k1' })}`;
    // return generalFetch(
    //     `${APIS.PAYMENT.KE_WITHDRAW}${objectToQueryString(req)}`,
    //     'GET'
    // );
};

export const SemiAutoDeposit = (req) => {
    // ?ukey=00f9b37e-c5ac-8742-fc7b-57c99798b818&val=100&unm=tester
    return generalFetch(
        `${APIS.PAYMENT.PECH_DEPOSIT}${objectToQueryString(req)}`,
        'GET'
    );
};

export const getKEDepositV2 = (req) => {
    return generalFetch(
        `${APIS.PAYMENT.KE_WITHDRAW}${objectToQueryString({ ...req, hd: (IS_AW_KE_PROD || IS_BITSBET_PROD) ? 'kea' : 'k1' })}`,
        'GET'
    );
};

export const getKEWithdrawlV2 = (req) => {
    return generalFetch(
        `${APIS.PAYMENT.KE_WITHDRAW}${objectToQueryString({ ...req, hd: (IS_AW_KE_PROD || IS_BITSBET_PROD) ? 'kea' : 'k1' })}`,
        'GET'
    );
};

export const postKEMepesaUrl = (url, req) => {
    return generalFetch(
        `${url}`,
        'POST',
        req
    );
};


export const postGWDepositV2 = (req) => {
    return generalFetch(
        `${APIS.PAYMENT.GW_V2_DEPOSIT}`,
        'POST',
        req
    );
};


export const postGWWithdrawalV2 = (req) => {
    return generalFetch(
        `${APIS.PAYMENT.GW_V2_WITHDRAWAL}`,
        'POST',
        req
    );
};

export const getGWBankListV2 = () => {
    return generalFetch(
        `${APIS.PAYMENT.GW_V2_BANK}`,
        'GET'
    );
};
