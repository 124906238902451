/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AffiliateOverview from '@components/shared/AffiliateOverview';
import {
    Button,
    ColumnWrap,
    CustomColumn,
    PaymentPageWrap,
    PolicyWrap,
} from '@components/shared/View';
import {
    getUserData,
    newUserBank,
    setUserBank,
    updateUserBank,
} from '@api/user';
import { useGlobalProps } from '@hooks/useGlobalProps';
import {
    STATUS_CODE,
    ADD_BANK_STATUS_CODE,
} from '@constants/api';
import AlertModal from '@components/shared/modals/AlertModal';
import {
    displayAmountWithDecimalPlace,
    displayAmountWithPrecision,
} from '@utils/util';
import { setVNWithdrawV3 } from '@api/payment';
import { parseErrorRes } from '@utils/parseErrors';
import ConfirmModal from '@components/shared/modals/ConfirmModal';
import { FONT_SIZE } from '@constants/numbers';
import { getWPolicy } from '@api/other';
import { Col, Row } from 'antd';
import BankAccountModal from '@components/shared/modals/BankAccountModal';
import { getVNBankListV3 } from '@api/payment';
import SingleBankTable, {
    parseBankRes,
} from '@components/bankAccount/SingleBankTable';
import { Spin } from 'antd';
import AmountColumn from '@components/shared/AmountColumn';
import { getUserInfo } from '@api/user';

const INIT_ALERT = {
    show: false,
    title: '',
};

export default function WithdrawalVNV3Page() {
    const [amount, setAmount] = useState('');
    const [defaultBank, setDefaultBank] = useState(null);
    const [addBankModalInfo, setAddBankModalInfo] = useState({
        display: false,
        item: null,
    });

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ ...INIT_ALERT });
    const [policy, setPolicy] = useState(null);
    const [bankoutList, setBankoutList] = useState([]);
    // const [messageApi, contextHolder] = message.useMessage();

    const {
        user,
        userData,
        setUserData,
        balance,
        decimalPlace,
        withdrawAmountOptions,
        mainUnit,
        mainUnitRaw,
        secondUnitRaw,
        unitRate,
        getBalance,
        pixelCodes,
        withdrawStepperOptions
    } = useGlobalProps();

    const { i18n, t } = useTranslation();
    const tCommon = t('common', { returnObjects: true });
    const tPayment = t('payment', { returnObjects: true });
    const tBank = t('bank', { returnObjects: true });
    const tError = t('error', { returnObjects: true });
    const [amountInfos, setAmountInfos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(withdrawAmountOptions).length > 0) {
            const infos = [];
            if (withdrawAmountOptions.amounts) {
                for (const amount of withdrawAmountOptions.amounts) {
                    const info = {
                        amount,
                        currency: withdrawAmountOptions.currency,
                        pointUnit: withdrawAmountOptions.pointUnit,
                        points: withdrawAmountOptions.rate
                            ? amount * parseFloat(withdrawAmountOptions.rate)
                            : 0,
                        rate: withdrawAmountOptions.rate
                            ? parseFloat(withdrawAmountOptions.rate)
                            : 0,
                    };
                    infos.push(info);
                }
            }
            setAmountInfos(infos);
        }
    }, [withdrawAmountOptions]);


    const getPolicy = async () => {
        const res = await getWPolicy(i18n.language);
        if (res && res[0]) setPolicy(res[0]);
        else setPolicy(null);
    };

    const handleInputChange = async (e) => {
        const targetValue = e.target.value;
        const name = e.target.name;
        if (name === 'amount') {
            if (balance?.bln && targetValue > balance.bln)
                setAmount(balance.bln);
            else setAmount(targetValue);
        }
    };

    const getBankaccounts = async () => {
        if (user?.ukey) {
            const res = await getUserData({ ukey: user?.ukey });
            if (
                res.rtn === STATUS_CODE.SUCCESS &&
                res?.acc_list &&
                res.acc_list.length > 0
            ) {
                setUserData(res);
                const bank = res.acc_list.filter((b) => b[5] === true)?.[0];

                if (bank.length > 0) {
                    setDefaultBank(parseBankRes([bank]));
                }
            }
        }
    };

    const onToggleAddBankModal = () => {
        setAddBankModalInfo({
            ...addBankModalInfo,
            display: !addBankModalInfo.display,
        });
    };

    useEffect(() => {

        getVNBankListV3().then((res) => {

            if (res?.data) {
                const bankList = res.data.map((item) => [item.bankCode, item.bank]);
                setBankoutList(bankList);
            } else {
                setBankoutList([

                ]);
            }

        });

    }, []);


    const onChangeAccount = (item) => {
        setAddBankModalInfo({ display: true, item });
    };

    const addBankaccount = async (req) => {
        if (req.id) {
            const res = await updateUserBank({
                idx: req.id,
                ukey: user.ukey,
                bank: req.bank,
                banknm: req.fullName,
                bankacc: req.account,
            });
            if (res?.rtn === ADD_BANK_STATUS_CODE.SUCCESS) {
                setAddBankModalInfo({ display: false, item: null });
                getBankaccounts();
            } else {
                setErrorAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseErrorRes(tError, res),
                });
            }
            return;
        }
        const res = await newUserBank({
            ukey: user.ukey,
            bank: req.bank,
            banknm: req.fullName,
            bankacc: req.account,
        });
        if (
            res?.rtn === ADD_BANK_STATUS_CODE.SUCCESS ||
            res?.rtn === ADD_BANK_STATUS_CODE.ACCOUNT_EXIST
        ) {
            const bindRes = await setUserBank({
                ukey: user.ukey,
                bank: req.bank,
                bankacc: req.account,
            });

            if (bindRes?.rtn !== STATUS_CODE.SUCCESS) {
                setErrorAlert({
                    show: true,
                    title: tCommon['common@failed'],
                    message: parseErrorRes(tError, res),
                });
            } else {
                setAddBankModalInfo({ display: false, item: null });
                getBankaccounts();
            }
        } else {
            setErrorAlert({
                show: true,
                title: tCommon['common@failed'],
                message: parseErrorRes(tError, res),
            });
        }
    };

    const getFee = () => {
        if (
            !amount ||
            !balance?.out_fee_per
        )
            return 0;
        try {
            const basicAmount = parseInt(amount);
            return displayAmountWithDecimalPlace(
                basicAmount * (balance?.out_fee_per / 100),
                decimalPlace
            );
        } catch (e) {
            return 0;
        }
    };

    useEffect(() => {
        if (user?.ukey) getBalance();
    }, [user, getBalance]);


    useEffect(() => {
        getPolicy();
    }, [i18n.language]);



    useEffect(() => {
        if (userData && userData?.acc_list) {
            const bank = userData?.acc_list.filter((b) => b[5] === true)?.[0];
            if (bank && bank.length > 0) {
                setDefaultBank(parseBankRes([bank]));
            } else {
                // setAddBankModalInfo({ display: true, item: null });
            }
        }

    }, [userData]);


    const handleSelectAmount = (val) => {
        setAmount((prev) => {
            if (withdrawStepperOptions && withdrawStepperOptions.display && parseFloat(val) > 0 && prev !== '') {
                if (user?.ko_max > 0 && (parseFloat(prev) + parseFloat(val) > user?.ko_max)) {
                    return user?.ko_max;
                };
                if (balance?.out_min > 0 && ((parseFloat(prev) || 0) + parseFloat(val) < balance?.out_min)) {
                    return balance?.out_min;
                };
                if (balance?.bln && (parseFloat(prev) + parseFloat(val) > balance?.bln)) {
                    return balance.bln;
                }

                return (parseFloat(prev) || 0) + parseFloat(val);

            } else {

                if (val === 0) return '';

                if (user?.ko_max > 0 && (parseFloat(val) > user?.ko_max)) {
                    return user?.ko_max;
                };
                if (balance?.out_min > 0 && (parseFloat(val) < balance?.out_min)) {
                    return balance?.out_min;
                };

                if (balance?.bln && (parseFloat(val) > balance?.bln)) {
                    return balance.bln;
                }

                return val;
            }
        });
    };

    const doWithdrawal = async () => {
        if (isLoading) return;



        setIsLoading(true);

        const [{ bankcode, account, name }] = defaultBank;
        console.log(' defaultBank 3 -> ', defaultBank);


        let ukey = user.ukey;

        const userInfoRes = await getUserInfo({ dvc: user?.device_id });

        console.log(' ukuserInfoReseyRes -> ', userInfoRes);

        if (userInfoRes.ukey) {
            ukey = userInfoRes.ukey;
        }

        const balanceRes = await getBalance();

        if (!balanceRes || amount > balanceRes?.bln) {
            setErrorAlert({
                show: true,
                title: tCommon['common@failed'],
                message:
                    tError[
                    'error@deposit_insufficient_amount'
                    ],
            });
            setIsLoading(false);
            return;
        }

        // const theAmount = parseFloat(amount).toFixed(2);
        // const amountWithFeeDeducted = theAmount - getFee();


        //amount=500&userId=0912231115&ukey=51bf18a0-1\6c4-c5ac-1156-2df6e4186e18&number=712&prod=0&bank_account=abcdefg&bank_id=1548&bank_no=0123456789
        const res = await setVNWithdrawV3('vnd_wysm_03', {
            amount: parseFloat(parseFloat(amount).toFixed(2)),
            fee: parseFloat(parseFloat(getFee()).toFixed(2)),
            ukey: ukey,
            acc: user.acc,
            customerBankCode: bankcode,
            customerBankAccount: account,
            customerBankHolderName: name,
        });



        setIsLoading(false);

        setShowConfirmModal(false);

        if (res?.result && res?.result.success && res?.result.code === '000000') {
            setErrorAlert({
                show: true,
                title: tCommon['common@success'],
                message: tPayment['payment@withdrawl-successfully'],
            });
            setAmount('');
        } else {
            // newTab.close();

            let message = tError['error@withdraw'];
            if (res.result && res.result.code && res.result.message) {
                message = `${res.result.message}. ${tError['error@error-code']} : ${res.result.code}`;

            }

            setErrorAlert({
                show: true,
                title: tCommon['common@failed'],
                message: message,
            });
        }


    };

    const canSubmit = defaultBank && amount >= balance?.out_min;
    const outOfBalance = defaultBank && amount > balance?.bln;

    const feeText = tPayment['payment@withdrawal-fee-msg']
        ?.replace('{percent}', balance?.out_fee_per)
        ?.replace('{fee}', getFee());
    const displayPolicy = policy && policy.content?.length > 0;

    return (
        <PaymentPageWrap>
            {/* {contextHolder} */}
            <AffiliateOverview />
            <SingleBankTable
                dataSource={defaultBank}
                tBank={tBank}
                onChangeAccount={onChangeAccount}
                onCreateAccount={onToggleAddBankModal}
                displayFullName={true}
            // emptyView={
            //     <Button onClick={onToggleAddBankModal}>
            //         {tBank['bank@add-bank']}
            //     </Button>
            // }
            />

            <ColumnWrap className="mt-20 mb-1 pl-3" alignItems="start">
                <AmountColumn
                    title={tPayment['payment@withdrawal-amount']}
                    mainUnit={mainUnit}
                    displayAmountOptions={withdrawAmountOptions && withdrawAmountOptions.display}
                    amount={amount}
                    onAmountChange={handleInputChange}
                    onSelecteAmount={handleSelectAmount}
                    amountInfos={amountInfos}
                    displayStepper={withdrawStepperOptions && withdrawStepperOptions.display}
                />
                {balance && (
                    <CustomColumn
                        notRequired
                        child={
                            <Row justify="space-between">
                                <Col>
                                    <WithdrawalFeeInfo>
                                        <p>
                                            {balance?.out_min > 0 && !user?.ko_max && `${tPayment['payment@withdrawal-min-amount']}: ${displayAmountWithPrecision(
                                                balance?.out_min
                                            )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                            {!balance?.out_min && user?.ko_max > 0 && `${tPayment['payment@withdrawal-max-amount']}: ${displayAmountWithPrecision(
                                                user?.ko_max
                                            )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                            {balance?.out_min > 0 && user?.ko_max > 0 && `${tPayment['payment@withdrawal-amount-limit']
                                                }: ${displayAmountWithPrecision(
                                                    balance?.out_min
                                                )} ~ ${displayAmountWithPrecision(
                                                    user?.ko_max
                                                )}${mainUnit && ` ${mainUnit}`
                                                }`}
                                        </p>

                                        {balance?.out_fee_per > 0
                                            && (
                                                <p>{feeText}</p>
                                            )}
                                    </WithdrawalFeeInfo>
                                </Col>
                            </Row>
                        }
                    />
                )}

                <CustomColumn
                    child={
                        <Button
                            onClick={() => {
                                if (!defaultBank) {
                                    setErrorAlert({
                                        show: true,
                                        title: tCommon['common@failed'],
                                        message:
                                            tError[
                                            'error@must_setup_account'
                                            ],
                                    });
                                    return;
                                }

                                if (!amount) {
                                    setErrorAlert({
                                        show: true,
                                        title: tCommon['common@failed'],
                                        message:
                                            tError[
                                            'error@must_fill_account'
                                            ],
                                    });
                                    return;
                                }

                                if (!canSubmit) {
                                    setErrorAlert({
                                        show: true,
                                        title: tCommon['common@failed'],
                                        message:
                                            tError[
                                            'error@deposit_insufficient_amount'
                                            ],
                                    });
                                    return;
                                }

                                if (outOfBalance) {
                                    setErrorAlert({
                                        show: true,
                                        title: tCommon['common@failed'],
                                        message:
                                            tError[
                                            'error@deposit_insufficient_balance'
                                            ],
                                    });
                                    return;
                                }

                                if (pixelCodes && pixelCodes.length > 0) {
                                    pixelCodes.forEach((code) => window.fbq('trackSingleCustom', code, 'WithdrawlSubmission'));
                                }

                                setShowConfirmModal(true);
                            }}
                        >
                            {tCommon['common@submit']}
                        </Button>
                    }
                    notRequired
                />
            </ColumnWrap>
            {displayPolicy && (
                <PolicyWrap>
                    <div dangerouslySetInnerHTML={{ __html: policy.content }} />
                </PolicyWrap>
            )}
            <ConfirmModal
                show={showConfirmModal}
                message={tPayment['payment@confirm-withdrawal']}
                children={
                    <>
                        {isLoading ? (
                            <SpinContainer>
                                <Spin
                                    style={{
                                        top: '50%',
                                        left: '50%',
                                        position: 'absolute',
                                    }}
                                />
                            </SpinContainer>
                        ) : (
                            <WithdrawalFeeInfo>
                                <p className="text-center">{`${tPayment['payment@withdrawal-amount']}: ${amount}${mainUnitRaw && ` ${mainUnitRaw}`
                                    }${secondUnitRaw && unitRate && ` (${amount * parseFloat(unitRate)} ${secondUnitRaw})`
                                    }`}</p>
                                {balance?.out_fee_per > 0 && (
                                    <p className="text-center">{feeText}</p>
                                )}
                            </WithdrawalFeeInfo>
                        )}
                    </>
                }
                onConfirm={doWithdrawal}
                onCancel={() => setShowConfirmModal(false)}
            />
            <BankAccountModal
                item={addBankModalInfo.item}
                show={addBankModalInfo.display}
                onToggleAddBankModal={onToggleAddBankModal}
                bankoutList={bankoutList}
                addBankaccount={addBankaccount}
                needFullName={true}
            />
            <AlertModal
                {...errorAlert}
                onConfirm={() => setErrorAlert({ ...INIT_ALERT })}
            />
        </PaymentPageWrap>
    );
}

const WithdrawalFeeInfo = styled.div`
    color: black;
    > p {
        margin: 0;
        margin-top: 5px;
        font-size: ${FONT_SIZE.ALERT_MODAL_TITLE};
        font-weight: 800;
    }
    .text-center {
        text-align: center;
    }
`;

const SpinContainer = styled.div`
    textalign: center;
    position: relative;
    width: 100%;
    height: 80px;
`;
